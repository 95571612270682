<template>
  <div>
    <vs-row vs-justify="center">
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
      >
        <b-card>
          <div slot="header">
            <h3>
              {{ $t('Create Users') }}
            </h3>
          </div>
          <div>
            <vs-row
              type="flex"
              vs-justify="space-around"
              vs-align="center"
            >
              <vs-col
                vs-w="2"
                vs-sm="12"
              >
                <vs-row>
                  <vs-col
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                    vs-w="12"
                  >
                    <vx-upload
                      v-model="UserData.avatar_id"
                      class="mt-2"
                    />
                  </vs-col>
                  <vs-col
                    v-if="!UserData.avatar_id"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                    vs-w="12"
                  >
                    {{ $t('Upload Your Avatar') }}
                  </vs-col>
                </vs-row>
              </vs-col>
              <vs-col
                vs-w="8"
                vs-sm="12"
              >
                <b-row>
                  <b-col md="6">
                    <vx-input
                      v-model="UserData.first_name"
                      class="mt-2"
                      :label="$t('First Name')"
                      :errors="errors.first_name"
                    />
                  </b-col>
                  <b-col md="6">
                    <vx-input
                      v-model="UserData.last_name"
                      class="mt-2"
                      :label="$t('Last Name')"
                      :errors="errors.last_name"
                    />
                  </b-col>
                  <b-col md="6">
                    <vx-input
                      v-model="UserData.email"
                      :label="$t('Email')"
                      type="email"
                      :errors="errors.email"
                    />
                  </b-col>
                  <b-col md="6">
                    <vx-input
                      v-model="UserData.phone"
                      type="number"
                      :label="$t('Phone')"
                      :errors="errors.phone"
                    />
                  </b-col>
                  <b-col md="6">
                    <vs-textarea
                      v-model="UserData.address"
                      label="Address"
                    />
                  </b-col>
                  <b-col md="6">
                    <vs-textarea
                      v-model="UserData.ex_address"
                      label="Address"
                    />
                  </b-col>
                </b-row>
              </vs-col>
              <vs-col vs-w="12">
                <br>
                <br>
                <br>
              </vs-col>
              <vs-col
                vs-w="11"
                align="end"
              >
                <vs-button
                  color="success"
                  class="mr-2 mb-2"
                  type="gradient"
                  @click="CreateNewUser()"
                >
                  {{ $t('Confirm') }}
                </vs-button>
                <vs-button
                  color="warning"
                  type="gradient"
                  class="mb-2"
                  @click="Reset()"
                >
                  {{ $t('Reset') }}
                </vs-button>
              </vs-col>
            </vs-row>
          </div>

        </b-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    errors: [],
    UserData: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      avatar_id: null,
      ex_address: '',
      address: '',
    },
  }),
  computed: {
  },
  mounted() {

  },

  methods: {
    CreateNewUser() {
      this.errors = []
      this.$store.dispatch('user/CreateUser', this.UserData).then(() => {
        this.Reset()
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
    Reset() {
      this.UserData.first_name = ''
      this.UserData.last_name = ''
      this.UserData.address = ''
      this.UserData.email = ''
      this.UserData.phone = ''
      this.UserData.avatar_id = null
      this.UserData.ex_address = ''
    },
  },
}
</script>
<style>
.select-label-placeholder {
  position: absolute;
}
</style>
